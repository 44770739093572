<template>
    <div class="board-container">
        <div role="table" class="board-table board-table--notice">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader" class="num">번호</div>
                    <div role="columnheader" class="tit">제목</div>
                    <div role="columnheader" class="writer">작성자</div>
                    <div role="columnheader" class="date">작성일</div>
                </div>
            </div>
            <div role="rowgroup">
                <div role="row">
                    <div role="cell" class="num">10</div>
                    <div role="cell"><div class="tit"><a href="">공지사항의 길고 짧은 제목이  옵니다. 공지사항의 제목이 옵니다. 공지사항의 길고 짧은 제목이  옵니다. 공지사항의 제목이 옵니다.</a></div></div>
                    <div role="cell" class="writer">id1234**</div>
                    <div role="cell" class="date"><span class="date">2020-01-01</span></div>
                </div>
                <div role="row">
                    <div role="cell" class="num">9</div>
                    <div role="cell"><div class="tit"><a href="">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</a></div></div>
                    <div role="cell" class="writer">id1234**</div>
                    <div role="cell" class="date"><span class="date">2020-01-01</span></div>
                </div>
                <div role="row">
                    <div role="cell" class="num">8</div>
                    <div role="cell"><div class="tit"><a href="">공지사항의 길고 짧은 제목이  옵니다. 공지사항의 제목이 옵니다.</a></div></div>
                    <div role="cell" class="writer">id1234**</div>
                    <div role="cell" class="date"><span class="date">2020-01-01</span></div>
                </div>
                <div role="row">
                    <div role="cell" class="num">7</div>
                    <div role="cell"><div class="tit"><a href="">공지사항의 길고 짧은 제목이  옵니다. 공지사항의 제목이 옵니다.</a></div></div>
                    <div role="cell" class="writer">id1234**</div>
                    <div role="cell" class="date"><span class="date">2020-01-01</span></div>
                </div>
                <div role="row">
                    <div role="cell" class="num">6</div>
                    <div role="cell"><div class="tit"><a href="">공지사항의 길고 짧은 제목이  옵니다. 공지사항의 제목이 옵니다.</a></div></div>
                    <div role="cell" class="writer">id1234**</div>
                    <div role="cell" class="date"><span class="date">2020-01-01</span></div>
                </div>
                <div role="row">
                    <div role="cell" class="num">5</div>
                    <div role="cell"><div class="tit"><a href="">공지사항의 길고 짧은 제목이  옵니다. 공지사항의 제목이 옵니다.</a></div></div>
                    <div role="cell" class="writer">id1234**</div>
                    <div role="cell" class="date"><span class="date">2020-01-01</span></div>
                </div>
                <div role="row">
                    <div role="cell" class="num">4</div>
                    <div role="cell"><div class="tit"><a href="">공지사항의 길고 짧은 제목이  옵니다. 공지사항의 제목이 옵니다.</a></div></div>
                    <div role="cell" class="writer">id1234**</div>
                    <div role="cell" class="date"><span class="date">2020-01-01</span></div>
                </div>
                <div role="row">
                    <div role="cell" class="num">3</div>
                    <div role="cell"><div class="tit"><a href="">공지사항의 길고 짧은 제목이  옵니다. 공지사항의 제목이 옵니다.</a></div></div>
                    <div role="cell" class="writer">id1234**</div>
                    <div role="cell" class="date"><span class="date">2020-01-01</span></div>
                </div>
            </div>
        </div>
        <div class="pagination pagination--block">
            <a class="pagination__backward" href=""></a>
            <a class="pagination__prev" href=""></a>
            <span class="pagination-lists">
                <a class="current">1</a>
                <a class="" href="">2</a>
            </span>
            <a class="pagination__next" href=""></a>
            <a class="pagination__forward" href=""></a>
        </div>
        <div class="board-buttons">
            <a class="button button--border" href=""><span>리스트</span></a>
            <a class="button button--primary" href=""><span>글쓰기</span></a>
        </div>
    </div>
</template>

<script>
export default{
    props: {
        items: { type: Array, default: null }
    },
}
</script>
