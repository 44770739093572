var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--notice",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("번호")]), _c('div', {
    staticClass: "tit",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("제목")]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성자")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성일")])])]), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("10")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("공지사항의 길고 짧은 제목이 옵니다. 공지사항의 제목이 옵니다. 공지사항의 길고 짧은 제목이 옵니다. 공지사항의 제목이 옵니다.")])])]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("id1234**")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v("2020-01-01")])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("9")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("id1234**")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v("2020-01-01")])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("8")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("공지사항의 길고 짧은 제목이 옵니다. 공지사항의 제목이 옵니다.")])])]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("id1234**")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v("2020-01-01")])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("7")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("공지사항의 길고 짧은 제목이 옵니다. 공지사항의 제목이 옵니다.")])])]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("id1234**")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v("2020-01-01")])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("6")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("공지사항의 길고 짧은 제목이 옵니다. 공지사항의 제목이 옵니다.")])])]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("id1234**")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v("2020-01-01")])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("5")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("공지사항의 길고 짧은 제목이 옵니다. 공지사항의 제목이 옵니다.")])])]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("id1234**")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v("2020-01-01")])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("4")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("공지사항의 길고 짧은 제목이 옵니다. 공지사항의 제목이 옵니다.")])])]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("id1234**")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v("2020-01-01")])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("3")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("공지사항의 길고 짧은 제목이 옵니다. 공지사항의 제목이 옵니다.")])])]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("id1234**")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v("2020-01-01")])])])])]), _c('div', {
    staticClass: "pagination pagination--block"
  }, [_c('a', {
    staticClass: "pagination__backward",
    attrs: {
      "href": ""
    }
  }), _c('a', {
    staticClass: "pagination__prev",
    attrs: {
      "href": ""
    }
  }), _c('span', {
    staticClass: "pagination-lists"
  }, [_c('a', {
    staticClass: "current"
  }, [_vm._v("1")]), _c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("2")])]), _c('a', {
    staticClass: "pagination__next",
    attrs: {
      "href": ""
    }
  }), _c('a', {
    staticClass: "pagination__forward",
    attrs: {
      "href": ""
    }
  })]), _c('div', {
    staticClass: "board-buttons"
  }, [_c('a', {
    staticClass: "button button--border",
    attrs: {
      "href": ""
    }
  }, [_c('span', [_vm._v("리스트")])]), _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "href": ""
    }
  }, [_c('span', [_vm._v("글쓰기")])])])]);

}]

export { render, staticRenderFns }